/* Font Import */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

* {
  box-sizing: border-box !important;
  font-family: poppins;
}

html {
  scroll-behavior: smooth;
  font-weight: 400;
}
#root {
  overflow-x: hidden;
}

img {
  object-fit: cover !important;
}
