@media (max-width: 768px) {
  p {
    font-size: 14px;
  }
  .logo-text {
    /* font-size: 1.2rem !important; */
    margin-top: 10px !important;
  }
  .main-logo {
    max-width: 80vw !important;
  }
}
