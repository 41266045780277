@media (max-width: 750px) {
  .landing .franchise {
    position: relative !important;
  }
  .landing .franchise .text-center.hidden {
    display: block !important;
  }
  .landing .franchise img {
    width: 80% !important;
  }
}
