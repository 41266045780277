* {
  margin: 0;
}
.snap-scroll-container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
}
.snap-scroll-item {
  height: 100vh;
  scroll-snap-align: start;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 5px;
}

.grid-container-1 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0 5px;
}
.grid-container-1.snap-scroll-item {
  height: auto !important;
}
.grid-item {
  position: relative;
  overflow: hidden;
}
.grid-image .thumbnail-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: grayscale(100%) brightness(0.5);
  -webkit-filter: grayscale(100%) brightness(0.5);
  transition:
    transform 10s ease-in-out 0s,
    filter 300ms linear;
}
.grid-item:hover .thumbnail-image {
  filter: grayscale(0) brightness(1);
  -webkit-filter: grayscale(0) brightness(1);
  cursor: pointer;
  transform: scale(1.2);
}
.titles {
  height: 160px;
  width: 100%;
  background-color: #eeeeee;
  z-index: 1;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: all 800ms;
}
.titles p {
  display: none;
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.26px;
  text-transform: uppercase;
  padding: 10px 10px;
  margin: 0 30px;
  opacity: 0;
}
.titles img {
  width: 220px;
  max-width: 95%;
}
.grid-item:hover .titles {
  height: 230px;
}
.grid-item:hover p {
  display: block;
  opacity: 1;
}
.grid-item:after .titles {
  animation-delay: 0.3s;
  animation-duration: 1.1s;
}
.grid-item:after p {
  animation-delay: 0.3s;
  animation-duration: 1.1s;
}

@media screen and (max-width: 1100px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 5px 0;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }
  .grid-container .grid-item {
    height: 33vh;
  }
  .grid-item {
    scroll-snap-align: start;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .grid-item:hover .titles {
    height: 75px;
  }
  .titles {
    width: 250px;
    height: 75px;
    bottom: 30px;
    right: 0px;
    transition: none;
  }
  .titles img {
    height: 80%;
    object-fit: contain !important;
  }
  .grid-image .thumbnail-image {
    object-fit: cover;
    width: 100vw;
    height: 50vh;
  }
  .grid-item:hover p {
    display: none;
    opacity: 0;
  }
  .titles p {
    margin: 0;
  }
}
@media screen and (max-width: 810px) {
  .grid-item {
    height: 49.5vh;
  }
  .grid-container-1 {
    grid-template-columns: repeat(1, 1fr);
    gap: 5px 0;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }
}
