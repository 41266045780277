div:where(.swal2-container) .swal2-html-container {
  text-align: left;
  /* padding: 1em 1.6em 0.3em; */
}

div:where(.swal2-container) div:where(.swal2-popup) {
  width: 39em !important;
}

form select:not([size]) {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

input[type="file"]::file-selector-button {
  /* visibility: hidden; */
  display: none;
}

input[type="file"] {
  text-align-last: center;
  padding-top: 107px;
  padding-bottom: 107px;
}
