section.achievements .achievements-svg circle {
  @apply fill-green opacity-100 transition-opacity duration-200 ease-in-out;
}

section.achievements .achievements-svg circle.hidden,
section.achievements .achievements-svg text.hidden {
  @apply opacity-0;
}

section.achievements .achievements-svg text {
  @apply fill-green text-2xl font-bold uppercase opacity-100 transition-opacity duration-300 ease-in-out;
  writing-mode: vertical-lr;
  text-orientation: sideways;
}

.achievements-by-year:not(.visible) {
  @apply ml-20 opacity-0 transition-all duration-1000 ease-in-out;
}

.achievements-by-year.visible {
  @apply ml-0 opacity-100 transition-all duration-1000 ease-in-out;
}
